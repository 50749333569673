/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import Header from "./common/Header";
import { PrivateRideApi,getPrivateRideFile } from "../api/api";
import { useUser } from "../ContextApis/ProvideUser";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "../components/common/Dropdown";
import Button from "../components/common/Button";
import InputField from "../components/common/InputField";
import fileDownload from "js-file-download";
import { Toast } from "./common/Toast";

const PrivateRide = () => {
  const ref = useRef();
  const { Detail, stationList, profileData } = useUser();
  const token = Detail?.token;
  var profileStation = profileData?.station;
  const [station, setStation] = useState();
  
  const [open, setToastOpen] = useState(false);
  const [message,setMessage]=useState();
  const [stationCode, setStationCode] = useState("");
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  let formatedDate = yyyy + "-" + mm + "-" + dd;

  const [Error, setError] = useState();
  // const [startDate, setStartDate] = useState(formatedDate);
  const [date, setDate] = useState(today.toISOString());
  const [startDate, setStartDate] = useState(new Date());


  useEffect(() => {
    if(Detail.role === "master"){
      SetStationDefault();
    }
  }, []);

  const SetStationDefault=()=>{
    let value = stationList.filter(
      (val) => val.id === profileStation
    );
    let {id, station_code,station_name  } = value[0] ? value[0] : "";
    ref.current['station'].value=station_name;
    setStation(id);
    setStationCode(station_code);
  }

  const handleChangeStation = (e) => {
    setError("");
    let value = stationList.filter(
      (val) => val.station_name === e.target.value
    );
    let { id, station_code } = value[0] ? value[0] : "";
    setStation(id);
    setStationCode(station_code);
    console.log(station_code);
  };
  const handleChangeDate = (e) => {
    setError("");
    setStartDate(e);
    setDate(e.toISOString());
    console.log(e.toISOString());
  };

  const handleSubmit = () => {
    console.log(stationCode, date);
    if(stationCode!==""&&date!==""){
    PrivateRideApi(stationCode, date).then((response) => {
      console.log(response);
      if (response.status === 200) {
        setToastOpen(true);
                setMessage("Manifest downloading");
                setTimeout(() => {setToastOpen(false);clearInput();}, 3000);
        console.log(response.data.data.file_url);
        getPrivateRideFile(response.data.data.file_url).then((res)=>{
          fileDownload(res.data, `${startDate}.xlsx`);});  
        
      }
    })
    .catch((error) => {
      setToastOpen(true);
                setMessage("Error");
                setTimeout(() => {setToastOpen(false);}, 3000);
   });
  }else{
      setError("All fields required");
    }
  };

  const clearInput = () => {
    ref.current["date"] = new Date();
    setStartDate(new Date());
    setDate(today.toISOString());
    setError("");
    if(Detail.role === "master"){
      SetStationDefault();
    }else{
      ref.current["station"].value = "";
      setStationCode("");
    }
  };

  

  return (
    <div>
    <Toast
            open={open}
            color={message==="Error"?true:false}
            message={message}
            onClose={() => setToastOpen(false)}
          />
      <Header />
      <div className="bl-container-grid">
        <div
          className="col-span-12 bl-auth__right  "
          style={{ backgroundColor: "#ffffff" }}
        >
          <div>
            <div className="bl-auth__container ">
              <br />
              <br />
              <div className="bl-auth__field undefined">
                <form className="input-container" ref={ref}>
                  <div
                    className="col-span-2 "
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      style={{
                        height: "55px",
                        display: "flex",
                        alignItems: "center",
                        width: "80px",
                      }}
                    >
                      Station :{" "}
                    </div>
                    <div className="bl-input">
                      <Dropdown
                        items={stationList}
                        item={station}
                        handleChangeDropdown={handleChangeStation}
                        name="station"
                        disable={Detail.role === "master"?true:false}
                        defaultSelectedItem="--Select Station--"
                        width="240px"
                      />
                    </div>
                  </div>
                  <br />
                  

                  <div
                    className="text-red-600 text-center"
                    styles={{ marginTop: "50px" }}
                  >
                    {Error}
                  </div>
                  <div className="text-center ">
                    <Button label="Submit" onClick={handleSubmit} />
                  </div>
                  <div className={"bl-auth__cta text-center mt-5"}>
                    <a href="#" onClick={clearInput}>
                      Clear
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivateRide;
