import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ProvideAuth } from './ContextApis/ProvideAuth'
import { ProvideUser } from './ContextApis/ProvideUser';
import Router from './routes/Router';
import './App.css';

function App() {
  return (
    <ProvideAuth>
    <ProvideUser>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ProvideUser>
  </ProvideAuth>
  );
}

export default App;
