const apiBaseURL = "https://apimaster.gobrightline.com/api/v1/station-master";
const ip="106.208.56.62";

const env = {
    ipAddress:ip,
    baseURL: apiBaseURL,
    api: {
      login:`${apiBaseURL}/login`,
      forgotPasswordApi:`${apiBaseURL}/forgot-password`,
      resetPassword:`${apiBaseURL}/change-password`,
    },
    authApi: {
      createUser:`${apiBaseURL}`,
      stationMasterList:`${apiBaseURL}?limit=10&page=`,
      deleteUserapi:`${apiBaseURL}/`,
      LogsApi:`${apiBaseURL}/action-logs?page=`,
      stationMasterProfileApi:`${apiBaseURL}/profile`,
      stationsApi:"https://apimaster.gobrightline.com/api/v1/stations",
      dashboardApi:`https://apimaster.gobrightline.com/api/v1/station-gate?page=`,
      statusApi:"https://apimaster.gobrightline.com/api/v1/station-gate/",
      editUserapi:`${apiBaseURL}/`,
      privateRide:"https://apimaster.gobrightline.com/api/v1/stations/private-ride"
    },
  };
  
  export default env;
  