import { useState } from 'react';

const _token_id = '__SESSION_TOKEN';

export default function useToken() {
  const getToken = () => {
    // return getCookie(_token_id);
    const tokenString = sessionStorage.getItem(_token_id);
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    // setCookie(_token_id, `${userToken}`);
    // set token in session storage
    sessionStorage.setItem(_token_id, JSON.stringify(userToken));
    setToken(userToken);
  };

  const clearToken = () => {
    // deleteCookie(_token_id);
    saveToken("");
  };

  return {
    setToken: saveToken,
    token,
    clearToken,
    saveToken,
  };
}

/*
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name, val ) {
  const date = new Date();
  const value = val;

  // Set it expire in 1 day
  date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);

  // Set it
  document.cookie =
    name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
}

export function getCookie(name) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  if (parts.length === 2) {
    // @ts-ignore
    return parts.pop().split(';').shift();
  }
}

export function deleteCookie(name) {
  const date = new Date();

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

  // Set it
  document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
}
