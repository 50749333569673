import React, {
  createContext,
 
  useContext,
} from 'react';
import { useProvideAuth } from '../CustomHooks/useProvideAuth';



export const authContext = createContext();

export const ProvideAuth = ({ children }) => {
  const auth =useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);
