// import { mock } from './mock/api.mock';
import env from './base';
import axios from 'axios';
import react from 'react'
import fileDownload from 'js-file-download'
const publicIp = require('public-ip');


async function ipaddress(){
  const ipv4 = await publicIp.v4() || "";
    console.log("ip--address",ipv4);
  return ipv4;
}

async function authLogIn( email, password,ip) {
  console.log(ip);
  let data = JSON.stringify({
      email: email,
      password: password,
    });
    return axios
   .post(env.api.login, data,{
    headers: {
      'Content-Type': 'application/json',
      // 'X-Forwarded-For': env.ipAddress,
      'Client-IP': ip,
    }}
    )
    .then((response) => response)
    
}

async function forgotPassword( email,ip) {
  return axios
    .post(env.api.forgotPasswordApi, {
      email: email,
    },
    {headers: {
      'Content-Type': 'application/json',
      'Client-IP': ip,
    }}
    )
    .then((response) => response)
    // .catch((error) => error);
}

async function fetchResetPassword( token,password,ip) {
  return axios
    .post(env.api.resetPassword, {
      password: password,
    },
    {headers: {
      'Content-Type': 'application/json',
      'x-access-token': `${token}`,
      'Client-IP': ip,
    }}
    )
    .then((response) => response)
    .catch((error) => error);
}


async function fetchStationMaster(token,page, ip) {
  console.log("Token is ---"+token);
  console.log("ipaddress is ---"+ipaddress);
    return axios
      .get(env.authApi.stationMasterList+page, {
        headers: {
            'x-access-token': `${token}`,
            'Client-IP': ip,
        },
      })
      .then((response) => response)
      .catch(function (error) {
        return error;
      });
  }

  async function createUserApi(token,firstname,lastname,email,station,role,ip) {
      var data=JSON.stringify({
        first_name:firstname, 
        last_name : lastname,
        email_id : email,
        role : role,
        station: station});
        
      return axios
      .post(env.authApi.createUser, data,
      {
        headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${token}`,
        'Client-IP': ip,
        
    },
  })
    .then((response) => response)
    // .catch((error) => error);
   
  }

  async function deleteUser(token, userId,ip) {
      console.log(env.authApi.deleteUserapi + userId);
    return axios
      .delete(env.authApi.deleteUserapi + `${userId}?=`, {
        headers: {
            'x-access-token': `${token}`,
            'Client-IP': ip,
        },
      })
      .then((response) => response)
      .catch(function (error) {
        return error;
      });
  }

  async function editUser(token, userId,firstname,lastname,email,station,role,ip) {
    var data={
      first_name:firstname, 
      last_name : lastname,
      email_id : email,
      role : role,
      station: station};
    console.log(env.authApi.editUserapi + userId);
    return axios
    .put(env.authApi.editUserapi + userId, data, 
      {
      headers: {
        'Content-Type': 'application/json',
          'x-access-token': `${token}`,
          'Client-IP': ip,
      },
    })
    .then((response) => response)
    .catch(function (error) {
      return error;
    });
}

  async function fetchLogs(token,pages,station,startDate,endDate,logtype) {
    let logtypeApi=`&log_type=${logtype}`
    let stationApi=`&station_id=${station}`
    let DateApi=`&start_date=${startDate}&end_date=${endDate}` 
    // console.log(station&&startDate&&logtype?env.authApi.LogsApi+pages+logtypeApi+stationApi+DateApi
    //   :startDate&&logtype? env.authApi.LogsApi+pages+logtypeApi+DateApi
    //   :station&&logtype?env.authApi.LogsApi+pages+logtypeApi+stationApi
    //   :station&&startDate?env.authApi.LogsApi+pages+stationApi+DateApi
    //   :logtype?env.authApi.LogsApi+pages+logtypeApi
    //   :startDate? env.authApi.LogsApi+pages+DateApi
    //   :station?env.authApi.LogsApi+pages+stationApi
    //   :env.authApi.LogsApi+pages);
    return axios
      .get(station&&startDate&&logtype?env.authApi.LogsApi+pages+logtypeApi+stationApi+DateApi
        :startDate&&logtype? env.authApi.LogsApi+pages+logtypeApi+DateApi
        :station&&logtype?env.authApi.LogsApi+pages+logtypeApi+stationApi
        :station&&startDate?env.authApi.LogsApi+pages+stationApi+DateApi
        :logtype?env.authApi.LogsApi+pages+logtypeApi
        :startDate? env.authApi.LogsApi+pages+DateApi
        :station?env.authApi.LogsApi+pages+stationApi
        :env.authApi.LogsApi+pages, {
        headers: {
            'x-access-token': `${token}`,
        },
      })
      .then((response) => response)
      .catch(function (error) {
        return error;
      });
  }

  async function stationMasterProfile(token) {
    return axios
      .get(env.authApi.stationMasterProfileApi, {
        headers: {
            'x-access-token': `${token}`,
        },
      })
      .then((response) => response)
      .catch(function (error) {
        return error;
      });
  }

  async function stationsListApi(token) {
    return axios
      .get(env.authApi.stationsApi, {
        headers: {
            'x-access-token': `${token}`,
            'Authorization':'Basic Nk53NWNuRlVpR2JScGJMdXVDUkY6YkFpWUJKYm5JSnFreXJiNmdSQmo=',
        },
      })
      .then((response) => response)
      .catch(function (error) {
        return error;
      });
  }

  async function fetchDashboardApi(token,page,station) {
    return axios
      .get(env.authApi.dashboardApi+page+`&station_id=${station}`, {
        headers: {
            'x-access-token': `${token}`,
        },
      })
      .then((response) => response)
      .catch(function (error) {
        return error;
      });
  }

  async function fetchChangeStatus( token,status,gateNo,ip) {
    return axios
      .put(env.authApi.statusApi+gateNo, {
        active_status: status,
      },
      {headers: {
        'x-access-token': `${token}`,
        'Content-Type': 'application/json',
        'Client-IP': ip,
      }}
      )
      .then((response) => response)
      // .catch(function (error) {
      //   return error;
      // });
  }

  async function PrivateRideApi(station_code,datevalue) {
      let config = {
        method: 'get',
        url: env.authApi.privateRide,
        headers: { 'Authorization': 'Basic Nk53NWNuRlVpR2JScGJMdXVDUkY6YkFpWUJKYm5JSnFreXJiNmdSQmo=' },
        params: {
          'station-code':station_code,
        }
    };

    return axios(config)
      .then((response) => response)
      // .catch(function (error) {
      //   return error;
      // });
  }
  
  async function getPrivateRideFile(url) {
    return axios
      .get(url,{
        responseType: 'blob',
      })
      .then((response) => response)
      .catch(function (error) {
        return error;
      });
  }



export {
  authLogIn,
  fetchStationMaster,
  createUserApi,
  deleteUser,
  forgotPassword,
  fetchLogs,
  stationMasterProfile,
  stationsListApi,
  fetchDashboardApi,
  fetchChangeStatus,
  editUser,
  fetchResetPassword,
  ipaddress,
  PrivateRideApi,
  getPrivateRideFile,
};
