import React,{useState,useEffect} from "react";
import { fetchResetPassword,ipaddress} from '../api/api';
import { useAuth } from '../ContextApis/ProvideAuth';
import { useHistory, useLocation } from 'react-router-dom';
import '../styles.css';
import { useUser } from '../ContextApis/ProvideUser';
import logo from '../assets/brightline-logo-charcoal.svg';
import { Toast } from "./common/Toast";
//import { useProvideAuth } from '../CustomHooks/useProvideAuth';

function ResetPassword () {
    const auth =useAuth();
   
    const history = useHistory();
    const { Detail } = useUser();
    const token = Detail?.token;
    const location = useLocation();
    const [newPassword,setNewPassword]=useState();
    
    const [confirmPassword,setConfirmPassword]=useState();
    const [open, setToastOpen] = useState(false);
    const [message,setMessage]=useState();
    const { from }= location.state || { from: { pathname: '/' } };
    const [logInError,setLoginError]=useState();
    const [ip,SetIp]=useState();
    useEffect(() => {
        ipaddress().then((res)=>SetIp(res));
      }, []);
    const handleNewPassword=(e)=>{
        setNewPassword(e.target.value);
    };
    const handleConfirmedPassword=(e)=>{
        setConfirmPassword(e.target.value);
    };
    const handleOnSubmitButtonClicked = () => {
    if(newPassword&&confirmPassword){
      if(newPassword===confirmPassword){ 
        fetchResetPassword(token,newPassword,ip)
          .then((res) => {
            console.log('res', res);
            
          
            if (res.status === 200) {
                setToastOpen(true);
                setMessage("Password Changed");
                setTimeout(() => {setToastOpen(false);history.replace('/');}, 3000);
                
             
            }
            
          })
          .catch((error) => {
             let val=`${error}`;
             let value=val.split(' ');
                if(value[6]==='401'){
                setLoginError("invalid credentials");
                }
                if(value[6]==='400'){
                    setLoginError("All fields required");
                    }
                else{
                    console.log('error.status', value);
                }
            
          });}
              else{
                setLoginError("confirm password incorrect!");
          }}else{
            
                setLoginError("All fields required");
          
          }
      };
      const logOut=()=>{

        auth.signOut(() => history.replace('/'));
    };
    const DashboardMove=()=>{
        console.log("reset");
        // auth.resetPassword(()=>history.replace('/resetPassword'));
        history.replace('/dashboard');
    };
    
  return (
    
    <div id="__next"><div className="bl-shell"></div>
    <header className="bl-container bl-meganav ">
        <div className="bl-container-grid bl-meganav__inner">
            <div className="bl-meganav__brand">
                <div className="bl-meganav__logo">
                    <a title="Brightline Home" href="/">
                        <img alt="Brightline" src={logo} width="116px" height="28px"/>
                    </a>
                </div>
            </div>
            
        </div>
    </header>
    <Toast
            open={open}
            message={message}
            onClose={() => setToastOpen(false)}
          />
    <main className="bl-main text-bl-black block">
        <section className="bl-container bl-auth">
            <div className="bl-container-grid">
                
            <div className="col-span-12 bl-auth__right">
                    <div><div className="bl-auth__container">

                    
                        <h2 className={"bl-auth__cta"}>Reset Password</h2>
                        
                        <div className="bl-auth__field undefined">
                            <div className="input-container">
                              
                                <label className={"bl-input"}>
                                    <input type="password" placeholder=" " value={newPassword} name="password" style={{width:"300px"}} onChange={handleNewPassword}/>
                                    <span className="label_bl">New Password</span>
                                </label>

                                <label className={"bl-input"}>
                                    <input type="password" placeholder=" " value={confirmPassword} name="password" style={{width:"300px"}} onChange={handleConfirmedPassword}/>
                                    <span className="label_bl">Confirm Password</span>
                                </label>
                                
                                <div className="bl-input__helper-text">

                                </div></div>
                                
                                </div>
                                <div className="text-red-600 w-49 text-center" >{logInError}</div>
                                
                                <div className={"bl-auth__cta"}>
                                    <button className="h-12 w-49 bg-bl-yellow text-black text-sm uppercase border-white rounded-sm hover:shadow-default focus:outline-none" 
                                    onClick={handleOnSubmitButtonClicked}>Submit</button>
                                </div>
                               
                               
                                <div className={"bl-auth__cta"}><a href="/#" onClick={logOut} >Login</a></div>
                                <div className={Detail.force_change_password===0?"bl-auth__cta":"bl-input-hidden"}><a href="/dashboard" onClick={DashboardMove} >DashBoard</a></div>
                                </div></div></div></div></section></main>
    </div>
    

  );
};



export default ResetPassword;
