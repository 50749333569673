import React from "react";

export const Toast = ({ message, open = false ,color}) => {
  return (
    open && (
      <div className={color!==true?"toast p-3 text-black bg-yellow-400  rounded-sm w-56 text-sm font-semibold":"toast p-3 text-black bg-red-400  rounded-sm w-56 text-sm font-semibold"}>
       {message}
      </div>
    )
  );
};
