/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import InputField from "../components/common/InputField";
import Button from "../components/common/Button";
import Users from "../components/Users";
import { fetchStationMaster, createUserApi, editUser ,ipaddress} from "../api/api";
import { useUser } from "../ContextApis/ProvideUser";
import { useHistory } from "react-router-dom";
import Header from "./common/Header";
import Dropdown from "../components/common/Dropdown";
import { useAuth } from "../ContextApis/ProvideAuth";
import { Toast } from "./common/Toast";
import ReactPaginate from "react-paginate";

function UserManagement() {
  const [edit, setEdit] = useState(false);
  const [open, setToastOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastName] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [userId, setUserId] = useState();
  const [Error, setError] = useState();
  const [station, setStation] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setcurrentPage] = useState(1);
  const [displayMessage,setDisplayMessage]=useState("");
  const roles = ["admin", "master"];
  // const stations = [1, 2, 3, 4, 5, 6]
  const auth = useAuth();
  const ref = useRef();
  const history = useHistory();
  const { Detail, stationList } = useUser();
  const token = Detail?.token;
  const [message, setMessage] = useState();
  const [ip,SetIp]=useState();
 
  useEffect(() => {
    ipaddress().then((res)=>SetIp(res));
    getStationMaster();
  }, []);

  useEffect(() => {
    getStationMaster();
  }, [currentPage]);

  const getStationMaster = async () => {
    fetchStationMaster(token,currentPage, ip).then((response) => {
      if (response.status === 200) {
        console.log(response.data.data.page_total);
        if(response.data.data.items===null){
          setDisplayMessage("No Station Master To Display.");
        }
        else{
          setDisplayMessage("");
          setUserList(response.data.data.items);
        setPageCount(response.data.data.page_total);
        }
        
      }
      if (response.status === 401) {
        auth.signOut(() => history.replace("/"));
      }
    });
  };
  const handleChangeRole = (e) => {
    setRole(e.target.value);
  };
  const handleChangeStation = (e) => {
    // setStation(e.target.value);
    let data = stationList?.filter(
      (val) => val.station_name === e.target.value
    );
    console.log(data[0].id);
    setStation(data[0].id);
  };
  const handleChangeFirstName = (e) => {
    setFirstname(e.target.value);
  };
  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePageChange = (selectedObject) => {
    console.log("onpage change", selectedObject);
    setcurrentPage(selectedObject.selected + 1);
    // getStationMaster();
  };

  const handleOnEdit = (idData) => {
    setError("");
    setUserId(idData);
    setEdit(true);
    let data = userList.filter((el) => el.id === idData);

    ref.current["firstname"].value = data[0].first_name;
    ref.current["lastname"].value = data[0].last_name;
    ref.current["email"].value = data[0].email_id;
    ref.current["role"].value = data[0].role;
    
    ref.current["station"].value = data[0].station_name;
    setFirstname(data[0].first_name);
    setLastName(data[0].last_name);
    setEmail(data[0].email_id);
    setRole(data[0].role);
    setStation(data[0].station);
  };

  const handleChange = () => {
    if (firstname && lastname && email && station && role) {
      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
        !edit
          ? createUserApi(token, firstname, lastname, email, station, role,ip)
              .then((res) => {
                // console.log(token, firstname, lastname, email, station,role);
                console.log("res--->", res.message);

                //const { data } = res;
                if (res.status === 200) {
                  setToastOpen(true);
                  setMessage("Profile Created!");
                  getStationMaster();
                  setTimeout(() => setToastOpen(false), 3000);
                  clearInput();
                  history.replace("/UserManagement");
                  setError("");
                }
              })
              .catch((error) => {
                console.log("error.status", error.message);
                let val=`${error.message}`;
                let value=val.split(' ');
                if(value[5]==='400'){
                setError("Duplicate entry");
                }
              })
          : editUser(token, userId, firstname, lastname, email, station, role,ip)
              .then((res) => {
                console.log(token, firstname, lastname, email, station);
                // console.log('res--->', res);

                //const { data } = res;
                if (res.status === 200) {
                  setToastOpen(true);
                  setMessage("User Details Edited!");
                  clearInput();
                  getStationMaster();
                  setTimeout(() => setToastOpen(false), 3000);
                  history.replace("/UserManagement");
                  setEdit(false);
                  setError("");
                }
              })
              .catch((error) => {
                console.log("error.status", error);
              });
      } else {
        setError("Email not valid.");
      }
    } else {
      setError("All fields required");
    }
  };

  const onDelete = () => {
    setEdit(false);
    setToastOpen(true);
    setMessage("Successfully deleted user!");
    getStationMaster();
    setTimeout(() => setToastOpen(false), 3000);
  };

  const clearInput = () => {
    setFirstname("");
    setLastName("");
    setEmail("");
    ref.current["role"].value = "";
    ref.current["station"].value = "";
    setError("");
    setEdit(false);
  };

  return (
    <div>
      <Header />
      <Toast
        open={open}
        message={message}
        onClose={() => setToastOpen(false)}
      />

      <main className="bl-main text-bl-black block mt-6">
        <section className="bl-container bl-auth ">
          <div className="bl-container-grid-max ">
            <div className="col-span-4 " style={{ backgroundColor: "#ffffff" }}>
              <div>
                <div className="bl-auth__container">
                  <h2>Create User</h2>
                  <br />
                  <div className="bl-auth__field undefined">
                    <form className="input-container" ref={ref}>
                      <InputField
                        label="First Name"
                        onChange={handleChangeFirstName}
                        name="firstname"
                        value={firstname}
                      />
                      <InputField
                        label="Last Name"
                        onChange={handleChangeLastName}
                        name="lastname"
                        value={lastname}
                      />
                      <InputField
                        label="Email Address"
                        onChange={handleChangeEmail}
                        name="email"
                        value={email}
                      />
                      <Dropdown
                        items={roles}
                        item={role}
                        handleChangeDropdown={handleChangeRole}
                        name="role"
                        disable={edit}
                        defaultSelectedItem="  Role  "
                        width="240px"
                      />
                      <div className="bl-input">
                        <Dropdown
                          items={stationList}
                          item={station}
                          handleChangeDropdown={handleChangeStation}
                          className="pt-5"
                          name="station"
                          defaultSelectedItem="   Station   "
                          width="240px"
                        />
                      </div>
                      <div
                        className="text-red-600 text-center"
                        styles={{ marginTop: "50px" }}
                      >
                        {Error}
                      </div>
                      <div className="text-center mt-5">
                        <Button label="Submit" onClick={handleChange} />
                      </div>
                      <div className={"bl-auth__cta text-center mt-5"}>
                        <a href="#" onClick={clearInput}>
                          Clear
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-8 ">
              <div
                className="input-container"
                style={{
                  border: "5px solid #d8d4d4",
                  backgroundColor: "#d8d4d4",
                }}
              >
                <tr
                  className="bl-container-grid-max-6"
                  style={{ backgroundColor: "#d8d4d4" }}
                >
                  <th className="col-span-1">First Name</th>
                  <th className="col-span-1">Last Name</th>
                  <th className="col-span-2 col-hide">Email Adderss</th>
                  <th
                    className="col-span-1 col-hide"
                    style={{ paddingLeft: "20px" }}
                  >
                    Role
                  </th>
                  <th className="col-span-1 col-hide">Station</th>
                </tr>
                <tr className={displayMessage?"bl-container-grid-10 mt-20":"bl-input-hidden"}>
                    <th className=" col-span-10 mt-40 text-gray-600 text-center text-32 font-semibold" >
                      {displayMessage}
                    </th></tr>
                {userList?.map((data, i) => (
                  <div key={i}>
                    <Users
                      firstname={data.first_name}
                      lastname={data.last_name}
                      email={data.email_id}
                      role={data.role}
                      station={data.station_name}
                      onEdit={() => handleOnEdit(data.id)}
                      userId={data.id}
                      onDeleteShow={onDelete}
                    />
                  </div>
                ))}
              </div>
              {pageCount > 1 ? ( 
                <ReactPaginate
                  pageCount={pageCount}
                  pageRange={1}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName={"container"}
                  previousLinkClassName={"activeLink"}
                  breakClassName={"page"}
                  nextLinkClassName={"activeLink"}
                  pageClassName={"page"}
                  disabledClassNae={"disabled"}
                  activeClassName={"active"}
                  activeLinkClassName={"activeLink"}
                  //initialPage={0}
                  pageLinkClassName={"pageLink"}
                  // forcePage={forcePage}
                  nextClassName={"previousnextPage"}
                  previousClassName={"previousnextPage"}
                />
              ) : null} 
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default UserManagement;
