/* eslint-disable jsx-a11y/role-supports-aria-props */
import React,{useState,useEffect} from "react";
import { useAuth } from '../../ContextApis/ProvideAuth';
import '../../styles.css';
import profile from '../../assets/profile.svg';
import logo from '../../assets/brightline-logo-charcoal.svg';
import { stationMasterProfile} from '../../api/api';
import { useUser } from '../../ContextApis/ProvideUser';
import { useHistory,useLocation} from 'react-router-dom';

const Header = () => {
    const [show,setShow]=useState(false);
    // const [profileData,setProfileData]=useState([]);
    const auth =useAuth();
    const history = useHistory();
    const { Detail ,profileData,setProfileData}= useUser();
    const token = Detail?.token;
    const location=useLocation();
    const [openMenu,setOpenMenu]=useState("collapse")
    
    useEffect(() => {
        handleFetchProfile();
       }, [token]);

       const handleFetchProfile = () => {
		stationMasterProfile(token)
			.then(res => {
                console.log("profile",res.data.data);
                if (res.status === 200) {
                    setProfileData(res.data.data);
                  
                  }
                  if (res.status === 401) {
                    auth.signOut(() => history.replace('/'));
                  
                  }
				
                               
			})
			.catch(error => console.error('Error', error));
	};

    // const getInitials = () => {
    //     // console.log(profileData);
    let initials= profileData?.first_name?.charAt(0).toUpperCase() + profileData?.last_name?.charAt(0).toUpperCase();
    //     return data?data:""
    //   };

    const logOut=()=>{
        setProfileData("");
        auth.signOut(() => history.replace('/'));
    };
    const resetPassword=()=>{
        console.log("reset");
        // auth.resetPassword(()=>history.replace('/resetPassword'));
        history.replace('/resetPassword');
    };    

    const menuExpand=()=>{
        if(openMenu==="collapse")
        {
            setOpenMenu("expand"); 
        }
        else{
            setOpenMenu("collapse");
        }
        
    };

  return (
    <div className="Header" ><div className="bl-shell"></div>
    <header className="bl-container bl-meganav ">
        <div className="bl-container-grid bl-meganav__inner">
            <div className="bl-meganav__brand">
                <div className="bl-meganav__logo">
                    <a title="Brightline Home" href={location.pathname}>
                        <img alt="Brightline" src={logo} width="116px" height="28px"/>
                    </a>
                </div>
                <button aria-haspopup="true" onClick={menuExpand} aria-label="Show Menu" className="bl-hamburger "></button>
            </div>
            <div className={`bl-meganav__menu bl-meganav__menu--${openMenu} `}>
                <nav aria-label="Main menu" className="bl-menu">
                    <ul className="bl-menu__nav">
                        <li className="bl-menu__category" id="19">
                        <a aria-haspopup="true" activeClassName="underlineNav" aria-controls="bl-menu__submenu-19" aria-expanded="false" className={"bl-menu__title"} href="/dashboard">
                            <span >DASHBOARD</span>
                            <div className={location.pathname==="/dashboard"?"ActiveNav":"bl-input-hidden"}></div>
                            
                        </a></li>
                        {Detail.role==="admin"?<li className="bl-menu__category" id="24">
                            <a aria-haspopup="true" aria-controls="bl-menu__submenu-24" aria-expanded="false" className="bl-menu__title " href="/UserManagement">
                                <span >USER MANAGEMENT</span>
                                <div className={location.pathname==="/UserManagement"?"ActiveNav":"bl-input-hidden"}></div>
                            </a>
                        </li>:""}
                        <li className="bl-menu__category col-hide" id="25">
                            <a aria-haspopup="true" aria-controls="bl-menu__submenu-25" aria-expanded="false" className="bl-menu__title " href="/Logs">
                                <span>LOGS</span>
                                <div className={location.pathname==="/Logs"?"ActiveNav":"bl-input-hidden"}></div>
                            </a>
                        </li>
                        <li className="bl-menu__category " id="26">
                            <a aria-haspopup="true" aria-controls="bl-menu__submenu-26" aria-expanded="false" className="bl-menu__title " href="/PrivateRide">
                                <span>PRIVATE RIDE</span>
                                <div className={location.pathname==="/PrivateRide"?"ActiveNav":"bl-input-hidden"}></div>
                            </a>
                        </li>
                       </ul>
                    </nav>
                    <div className="bl-account">
                        <button className="bl-account__desk" onMouseEnter={()=>setShow(true)} 
                            >
                            {/* <span className={"bl-account__profile"}><img width="24px" height="24px" src={profile}/></span> */}
                            <span className="bl-account__profile">
                            <span className="bl-account__initials">{initials?initials:(<img width="24px" alt="" height="24px" src={profile}/>)}</span>
                        </span>
                        </button>
                        <div className={show?"bl-account__nav bl-account__nav--loggedin bl-account__nav--expanded":"bl-account__nav bl-account__nav--loggedin"} onMouseEnter={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>
                        <p className="bl-account__userinfo" >
                        
                        <span className="bl-account__username" >Hi, {profileData?.first_name}</span>
                        
                        </p>
                        
                        <ul className="bl-account__links">
                        {/* <li>
                        <button className="block text-bl-blue font-semibold px-0 py-1 text-16">My Account</button>
                        </li> */}
                        <li>
                        <button className="block text-bl-blue font-semibold px-0 py-1 text-16" onClick={resetPassword}>Change Password</button>
                        </li>
                        <li>
                        <button className="block text-bl-blue font-semibold px-0 py-1 text-16" onClick={logOut}>Logout</button>
                        </li>
                        </ul></div>
                    </div>
                </div>
        </div>
    </header>
    
    </div>
  );
};
export default Header;
