import React,{useState,useEffect} from "react";
import { authLogIn ,forgotPassword,ipaddress} from '../api/api';
import { useAuth } from '../ContextApis/ProvideAuth';
import { useHistory, useLocation } from 'react-router-dom';
import '../styles.css';
import logo from '../assets/brightline-logo-charcoal.svg';
//import { useProvideAuth } from '../CustomHooks/useProvideAuth';
import { Toast } from "./common/Toast";

function Login () {
    const auth =useAuth();
    const location = useLocation();
    const [email,setEmail]=useState();
    const history = useHistory();
    const [password,setPassword]=useState();
    const [show,setShow]=useState(false);
    const { from }= location.state || { from: { pathname: '/dashboard' } };
    const [logInError,setLoginError]=useState();
    const [open, setToastOpen] = useState(false);
    const [message,setMessage]=useState();
    const [ip,SetIp]=useState();
    const handleEmail=(e)=>{
        setEmail(e.target.value);
    };
    const handlePassword=(e)=>{
        setPassword(e.target.value);
    };
    useEffect(() => {
      ipaddress().then((res)=>SetIp(res));
    }, []);
    
    const handleOnSignInButtonClicked = () => {
        console.log(email&&!password);
       if(email&&password)
       {
        if(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)){
        authLogIn(email,password,ip)
          .then((res) => {
            console.log('res', res);
           
            const { data } = res;
            
          
            if (res.status === 200) {
              auth.signIn(data.data, () => {
                  if(data.data.force_change_password===1){
                    history.replace('/resetPassword');
                  }
                  else{
                    history.replace('/dashboard');
                  }
                
              });
            }
            
          })
          .catch((error) => {
             let val=`${error}`;
             let value=val.split(' ');
                if(value[6]==='401'){
                setLoginError("Invalid credentials");
                }
                // if(value[6]==='400'){
                //     setLoginError("All fields required");
                //     }
                else{
                    console.log('error.status', val);
                }
            
          });}else{
            setLoginError("Email is not valid.");
          }}else{
            setLoginError("All fields required.");
          }
      };
      const handleOnForgotPasswordButtonClicked=()=>{
        if(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)){
        forgotPassword(email,ip)
        .then((res) => {
          console.log('res', res);
         
          
        
          if (res.status === 200) {
            setToastOpen(true);
            setMessage("Password sent to your email.");
            setTimeout(() => {setToastOpen(false);setShow(false);}, 3000);
            
           
          }
        })
        .catch((error) => {
          let val=`${error}`;
             let value=val.split(' ');
                if(value[6]==='401'){
                setLoginError("Invalid Email");
                }
          console.log('error.status', error);
        });}else{
            setLoginError("Email is not valid.");
        }
      };
    
      const forgotPass=()=>{
        setShow(!show)
        setLoginError("");
      };

  return (
    
    <div id="__next"><div className="bl-shell"></div>
    <header className="bl-container bl-meganav ">
        <div className="bl-container-grid bl-meganav__inner">
            <div className="bl-meganav__brand">
                <div className="bl-meganav__logo">
                    <a title="Brightline Home" href="/">
                        <img alt="Brightline" src={logo} width="116px" height="28px"/>
                    </a>
                </div>
            </div>
            
        </div>
    </header>
    <Toast
            open={open}
            message={message}
            onClose={() => setToastOpen(false)}
          />
    <main className="bl-main text-bl-black block">
        <section className="bl-container bl-auth">
            <div className="bl-container-grid">
                <div className="col-span-7 bl-auth__left" style={{backgroundImage:"url(http://40.76.249.9/sites/default/files/2021-06/login-compressed.jpg)"}}>
                    <h3><span>Go Places.</span><span>Go Brightline.</span></h3>
                </div>
                <div className="col-span-5 bl-auth__right">
                    <div><div className="bl-auth__container">

                        <h2 className={!show?"bl-auth__cta":"bl-input-hidden"}>Login</h2>
                        <h2 className={show?"bl-auth__cta":"bl-input-hidden"}>Forgot Password</h2>
                        
                        <div className="bl-auth__field undefined">
                            <div className="input-container">
                                <label className="bl-input">
                                    <input type="email" placeholder=" " value={email} name="email" style={{width:"300px"}} onChange={handleEmail}/>
                                    <span className="label_bl">Email Address</span>
                                </label>
                                <label className={!show?"bl-input":"bl-input-hidden"}>
                                    <input type="password" placeholder=" " value={password} name="password" style={{width:"300px"}} onChange={handlePassword}/>
                                    <span className="label_bl">Password</span>
                                </label>
                                
                                <div className="bl-input__helper-text">

                                </div></div>
                                
                                </div>
                                <div className={"text-red-600"}>{logInError}</div>
                                <div className={!show?"bl-auth__cta":"bl-input-hidden"}>
                                    <button className="h-12 w-49 bg-bl-yellow text-black text-sm uppercase border-white rounded-sm hover:shadow-default focus:outline-none" onClick={handleOnSignInButtonClicked}>Next</button>
                                </div>
                                <div className={show?"bl-auth__cta":"bl-input-hidden"}>
                                    <button className="h-12 w-49 bg-bl-yellow text-black text-sm uppercase border-white rounded-sm hover:shadow-default focus:outline-none" onClick={handleOnForgotPasswordButtonClicked}>Submit</button>
                                </div>
                               
                               

                                <div className={!show?"bl-auth__cta":"bl-input-hidden"}><a href="/#" onClick={forgotPass}>Forgot Password</a></div>
                                <div className={show?"bl-auth__cta":"bl-input-hidden"}><a href="/#" onClick={forgotPass}>Login</a></div>
                                </div></div></div></div></section></main>
    </div>
    

  );
};



export default Login;
