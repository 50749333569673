import React, {
  createContext,
  
  useContext,
  useState,
} from 'react';
import { useAuth } from './ProvideAuth';
import useSessionStorage from '../CustomHooks/useSessionStorage';


// @ts-ignore
export const userContext = createContext();

export const ProvideUser = ({ children }) => {
  
  const {Detail, setDetail }= useAuth();
  const [verificationEmail, setVerificationEmail] = useState('');
  const [stationList,setStationList] =useSessionStorage();
  const [profileData,setProfileData]=useSessionStorage('profile',[]);
  return (
    <userContext.Provider
      value={{
        Detail,
        setDetail,
        verificationEmail,
        setVerificationEmail,
        stationList,setStationList,
        profileData,setProfileData,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export const useUser = () => useContext(userContext);
