import { useState } from 'react';
// import { authSignOut } from '../api/api';
import useSessionStorage from './useSessionStorage';
import useToken from './useToken';

export const InitialUserDetail = {
  
 
  force_change_password:'',
  message:'',
  role: '',
  token:''
};

export const Auth = {
  isAuthenticated: false,
  currentUser:"",
  signIn(cb) {
    Auth.isAuthenticated = true;
    cb();
  },
  signOut(cb) {
    Auth.isAuthenticated = false;
    cb();
  },
  resetPassword(cb){
    Auth.isAuthenticated = false;
    cb();
  }
};

export const useProvideAuth = () => {
  
  const { token, setToken,clearToken } = useToken();

  const [Detail, setDetail] = useSessionStorage(
    '__DETAIL',
    InitialUserDetail,
  );

  const [user, setUser] = useState(() => {
    return token ? true : false;
  });

 const signIn = (resData, cb) => {
   
    Auth.signIn(() => {
      setToken(resData.token);
      setUser(true);
      setDetail(resData);
      Auth.currentUser=resData.role;
      cb();
    });
  };

  const signOut = (cb) => {
 
      
        Auth.signOut(() => {
          setUser(false);
          setToken(false);
          setDetail(InitialUserDetail);
          Auth.currentUser="";
          clearToken();
          cb();
        });
      };

      const resetPassword = (cb) => {
 
      
        Auth.resetPassword(() => {
          clearToken();
          setUser(false);
          console.log("auth --",Auth.isAuthenticated);
          // clearToken();
          cb();
        });
      };
   
  

  return {
    isAuthenticated: user,
    signIn:signIn,
    signOut,
    resetPassword,
    Detail: Detail,
    setDetail: setDetail,
  };
};
