import React, { useState, useEffect, useRef } from "react";
import Header from "./common/Header";
import { useAuth } from "../ContextApis/ProvideAuth";
import {
  stationsListApi,
  fetchDashboardApi,
  fetchChangeStatus,
  ipaddress,
} from "../api/api";
import { useUser } from "../ContextApis/ProvideUser";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Dropdown from "../components/common/Dropdown";
import Button from "../components/common/Button";
import { Toast } from "./common/Toast";

const Dashboard = () => {
  const auth = useAuth();
  const ref = useRef();
  const toastRef = useRef(false);
  const history = useHistory();
  const { Detail, stationList, setStationList, profileData } = useUser();
  const token = Detail?.token;
  
  const [station, setStation] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setcurrentPage] = useState(1);
  const [statusData, setStatusData] = useState();
  const [dahboardList, SetDashboardList] = useState([]);
  const [open, setToastOpen] = useState(false);
  const [forcePage, setforcePage] = useState();
  const accessto = ["Inbound", "Outbound", "Lounge", "Inbound & Outbound"];
  //const [filterdata,setFilter]=useState([]);
  const status = ["OPEN", "BOARDING", "EXIT", "TWOWAY", "EMERGENCY"];
  const [message, setMessage] = useState();
  const [ip,SetIp]=useState();
  
  useEffect(() => {
    ipaddress().then((res)=>SetIp(res));
    getStationList();
    console.log("useeffect ",station);
  }, []);
  useEffect(() => {
    getStationValue();
     getDashboardList();
     console.log("useeffect profiledata",station);
  }, [profileData]);
  useEffect(() => {
    if(station){
    getDashboardList();
    }
    console.log("useeffect p.station===station",station);
  }, [profileData.station === station]);
  useEffect(() => {
    // getStationList();

    getDashboardList();

    console.log("useeffect current page",station);
  }, [currentPage]);

  

  const getStationList = async () => {
    stationsListApi(token)
      .then((response) => {
        if (response.status === 200) {
          //console.log(response.data.data);
          setStationList(response.data.data);
        }
        if (response.status === 401) {
          auth.signOut(() => history.replace("/"));
        }
      })
      .catch((error) => {
        console.log("error.status", error);
      });
  };
  const getDashboardList = async () => {
    console.log(station);
    fetchDashboardApi(token, currentPage, station)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response);
          if (response.data.data.items === null) {
            setMessage("No Data To Display.");
          } else {
            setMessage("");
          }
          SetDashboardList(response.data.data.items);
          setPageCount(response.data.data.page_total);
          setforcePage(currentPage - 1);

          if (station) {
            // console.log(station);
            let value = stationList?.filter((val) => val.id === station);
            let { station_name } = value[0];
            ref.current["station"].value = station_name;
          }
        }
        if (response.status === 401) {
          auth.signOut(() => history.replace("/"));
        }
      })
      .catch((error) => {
        console.log("error.status", error);
      });
  };
  // var stations=["Udupi","Mangalore"];
  // const filterList=(val)=>{
  //     setFilter(dahboardList.filter((list) =>
  //     list.station_id===val

  //   ));

  // };
  const findStation=(data)=>{
    let value = stationList?.filter((val) => val.id === data);
    let { station_name } = value?value[0]:"";
    return station_name;
  };

  const onView = () => {
    let val = { selected: 0 };
    //handlePageChange(val);
    handleView(val);
    //currentPage===1?getDashboardList():setcurrentPage(1);
    //getDashboardList();
    //setforcePage(0);
    // console.log("onView");
  };

  const handleChange = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      let value = stationList.filter(
        (val) => val.station_name === e.target.value
      );
      let { id } = value[0];

      setStation(id);
    } else {
      setStation("");
    }
    // filterList(e.target.value);
  };

  const handleChangeStatus = (e) => {
    setStatusData(e.target.value);
  };
  const UpdateStatus = (gateNo) => {
    fetchChangeStatus(token, statusData, gateNo,ip)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setToastOpen(true);
          getDashboardList();
          setTimeout(() => setToastOpen(false), 3000);
          history.push("/dashboard");
        }
        if (response.status === 401) {
          auth.signOut(() => history.replace("/"));
        }
       
      })
      .catch((error) => {
        
        console.log("error.status", error);
        let val=`${error}`;
             let value=val.split(' ');
                if(value[6]!=='200'){
                  toastRef.current=true;
                  setToastOpen(true);
                  setTimeout(() => setToastOpen(false), 3000);
                  toastRef.current=false;
                }
                
      });
  };
  const handleView = (selectedObject) => {
    // console.log("onpage change", selectedObject);
    setcurrentPage(selectedObject.selected + 1);
    getDashboardList();
  };
  const handlePageChange = (selectedObject) => {
    // console.log("onpage change", selectedObject);
    setcurrentPage(selectedObject.selected + 1);
    //getDashboardList();
  };

  const getStationValue = () => {
    var profileStation = profileData?.station;
    setStation(Detail?.role==="master"?profileStation:"");
    // console.log(profileStation);
  };

  return (
    <>
      <Header />
      <Toast
        open={open}
        color={toastRef.current}
        message={toastRef.current?"Error Updating Status":"Status Updated!"}
        onClose={() => setToastOpen(false)}
      />

      <form ref={ref}>
        <div
          className="bl-container-grid-10"
          style={{ marginBottom: "20px", marginTop: "20px" }}
        >
          {/* <span className="col-span-6" style={{backgroundColor:"#ffffff"}}></span> */}

          <div
            className="col-end-9-form"
            style={{
              width: "250px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="w-18"
              style={{ height: "45px", display: "flex", alignItems: "center" }}
            >
              Station :{" "}
            </div>
            <Dropdown
              items={stationList}
              handleChangeDropdown={handleChange}
              name="station"
              defaultSelectedItem={"--All Station--"}
              width="150px"
            />
          </div>
          <div
            className="text-center col-end-11"
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button label="VIEW" onClick={onView} width="90px" />
          </div>
        </div>
      </form>
      <div className="input-container">
        <tr
          className="bl-container-grid-10"
          style={{
            backgroundColor: "#d8d4d4",
            textAlign: "center",
            padding: "20px 0 20px 0",
          }}
        >
          <th className="col-span-2 text-center">GATE NO</th>
          <th className="col-span-2">GATE NAME</th>
          <th className="col-span-2 col-hide">STATION NAME</th>

          <th className="col-span-2 col-hide">GATE TYPE</th>
          <th className="col-span-2-3 text-center">STATUS</th>
        </tr>
        <tr
          className={message ? "bl-container-grid-10 mt-20" : "bl-input-hidden"}
        >
          <th className=" col-span-10 mt-40 text-gray-600 text-center text-32 font-semibold">
            {message}
          </th>
        </tr>
        {dahboardList?.map((data, i) => (
          <div key={i}>
            <div
              className="bl-container-grid-10"
              style={{ backgroundColor: "#d8d4d4", padding: "0 0 1px 0" }}
            >
              <div className="col-span-10">
                <tr
                  className="bl-container-grid-10"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ffffff",
                    padding: "20px 0 20px 0",
                    alignItems: "center",
                  }}
                >
                  <td className="col-span-2">{data.gate_no}</td>
                  <td className="col-span-2">{data.gate_name}</td>
                  <td className="col-span-2 col-hide">{data.station_name}</td>

                  <td className="col-span-2 col-hide">
                    {accessto.filter((val, id) => id + 1 === data.access_to)}
                  </td>
                  {data.access_to!==2?<td className="col-span-2-3">
                    <select
                      //defaultValue={data.active_status}
                      onChange={handleChangeStatus}
                      className="h-10"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      {status.map((option, id) => (
                        <option key={id} value={option} selected={data.active_status===option?true:false}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <span
                      className="inline "
                      style={{ paddingLeft: "10px" }}
                    ></span>

                    {/* <div className="inline "></div> */}
                    {Detail.role==="admin"||findStation(profileData.station)===data.station_name?<Button
                      label="Update"
                      height="40px"
                      onClick={() => UpdateStatus(data.id)}
                      width="70px"
                    />:null}
                  </td>:null}
                </tr>
              </div>
            </div>{" "}
          </div>
        ))}
        {pageCount > 1 ? (
          <ReactPaginate
            pageCount={pageCount}
            pageRange={2}
            marginPagesDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={"container"}
            previousLinkClassName={"activeLink"}
            breakClassName={"page"}
            nextLinkClassName={"activeLink"}
            pageClassName={"page"}
            disabledClassNae={"disabled"}
            activeClassName={"active"}
            activeLinkClassName={"activeLink"}
            //initialPage={0}
            pageLinkClassName={"pageLink"}
            forcePage={forcePage}
            nextClassName={"previousnextPage"}
            previousClassName={"previousnextPage"}
          />
        ) : null}
      </div>
    </>
  );
};
export default Dashboard;
