import { useState } from 'react';

export function readFromStorage(key) {
  if (typeof window.sessionStorage !== undefined) {
    let storedData = sessionStorage.getItem(key);
    return JSON.parse(storedData);
  } else {
    throw new Error('Can not read from sessionStorage!');
  }
}

export function writeToStorage(key, value) {
  if (typeof window.sessionStorage !== undefined) {
    let dataToBeStored = JSON.stringify(value);
    return sessionStorage.setItem(key, dataToBeStored);
  } else {
    throw new Error('Can not write to sessionStorage!');
  }
}

export default function useSessionStorage(
  storageKey,
  initialStateValue,
) {
  const [state, setState] = useState(() => {
    const dataFromStore = readFromStorage(storageKey);
    return dataFromStore || initialStateValue;
  });

  function setPersistedState(value) {
    writeToStorage(storageKey, value);
    setState(value);
  }

  return [state, setPersistedState];
}
