import React from "react";
import PropTypes from 'prop-types';

const Dropdown = ({
    items,
    item,
    width,
    name,
    disable,
    handleChangeDropdown,
    className,
    defaultSelectedItem,
    ...rest
}) => {
    //const [item,setItem]=useState();
    let itemData=defaultSelectedItem==="   Station   "||defaultSelectedItem==="--Select Station--"||defaultSelectedItem==="--All Station--"?items?.map((val)=>val.station_name):items;

    return (
        // <div className={"bl-input "+`${className?"mt-5 h12":"pt-0"}`} style={{backgroundColor:"#ffffff"}}>
          <select name={name} disabled={disable} onChange={handleChangeDropdown} className="h-12" style={{backgroundColor:"#ffffff",width:`${width}`,paddingLeft:"10px",paddingTop:"0px",border:"1px solid #e5e7eb"}}>
            <option selected={true} value={""}>{defaultSelectedItem}</option>
            {(itemData? itemData:items)?.map((option,id) => (
              
             <option key={id} value={option}>{option}</option>
            ))}
          </select>
        // </div>
    );
};

Dropdown.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
    defaultSelectedItem: PropTypes.any,
    
};

export default Dropdown;