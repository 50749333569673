import React from "react";
import PropTypes from "prop-types";

const InputField = ({
  label,
  width,
  name,
  value,
  className,
  onChange,
  error,
  disabled,
}) => {
  return (
    <div className="input-each">
      <label className={"bl-input"}>
        <input
          disabled={disabled}
          placeholder=" "
          className={`w-${width}`}
          onChange={onChange}
          name={name}
          value={value}
        />
        <span className="label_bl">{label}</span>
      </label>
      {error && <p className="flex text-xs text-red-600">{error}</p>}
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  width: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
};

export default InputField;
