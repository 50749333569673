/* eslint-disable jsx-a11y/alt-text */
import React, { useState,useEffect} from "react";

import { deleteUser,ipaddress } from '../api/api';
import { useUser } from '../ContextApis/ProvideUser';

import DeleteModal from "./common/DeleteModal";

const Users = ({ firstname, lastname, email, role, station, onEdit, userId,onDeleteShow,
}) => {
    const { Detail } = useUser();
    const token = Detail?.token;
    
    const [deleteModal, setDeleteModal] = useState(false);
    const [ip,SetIp]=useState();
    useEffect(() => {
        ipaddress().then((res)=>SetIp(res));
      }, []);
    const onDelete = async () => {
        setDeleteModal(true);
    };
    const handleDelete = () => {
        // alert("user_id"+userId);
        setDeleteModal(false);
        //call service to delte
          deleteUser(token, userId,ip)
            .then((res) => {
                console.log('res', res);
             
                if (res.status === 200) {
                    onDeleteShow();
                }
            })
            .catch((error) => {
                console.log('error.status', error);
            });
      };

      

    return (
        <div>
            <DeleteModal
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                handleDelete={handleDelete}
            />
            <div className="bl-container-grid-max-6" style={{ textAlign:"left", marginBottom: "1px", backgroundColor: "#ffffff" }}>
                <td className="col-span-1">
                    {firstname}
                </td>
                <td className="col-span-1">
                    {lastname}
                </td>
                <td className="col-span-2 col-hide" style={{ paddingRight: "20px" }}>
                    {email}
                </td>
                <td className="col-span-1 col-hide" style={{ paddingLeft: "20px" }}>
                    {role}
                </td>
                <td className="col-span-1 col-hide">
                    {station}
                </td>
                <td rowSpan="2" className="col-span-1" style={{ paddingLeft: "20px" }}>
                    <div className="bl-account__userinfo">
                        <img src="../../ic_edit@2x.png" label="Edit user" style={{ width: "24px", height: "24px" ,marginRight: "10px"}} onClick={onEdit} />
                        <span style={{ width: "px", height: "24px" ,borderRight:"1px solid black"}}></span>
                        <img src="../../Ic_delete@2x.png" label="Delete user" style={{ marginLeft: "10px",width: "24px", height: "24px" }} onClick={onDelete} />
                    </div>    
                </td>
                
                
            </div>
        </div>
    );
};
export default Users;