import * as React from 'react';
import '../styles.css';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { useAuth } from '../ContextApis/ProvideAuth';
import { useUser } from '../ContextApis/ProvideUser';

// Pages
import Login from '../components/Login';
import Dashboard from '../components/Dashboard';
import UserManagement from '../components/UserManagement';
import Logs from '../components/Logs';
import ResetPassword from '../components/ResetPassword';
import PrivateRide from '../components/PrivateRide';


//import ErrorBoundary from '../Error/ErrorBoundry';



// eslint-disable-next-line no-undef
export default function Router() {
  
  const { Detail }= useUser();

 
 

  return (
    <div >
    

      <Switch>
       
        <Route exact path="/">
          <Login />
        </Route>
         
        <PrivateRoute exact path="/resetPassword">
          <ResetPassword />
        </PrivateRoute>
        {/* Private Routes */}
      
          <PrivateRoute exact path="/dashboard">
            
              <Dashboard/>
            
          </PrivateRoute>
          {Detail.role==="admin"?
          <PrivateRoute exact path="/userManagement">
            <div >
              <UserManagement/>
            </div>
          </PrivateRoute>:null
          }
          <PrivateRoute exact path="/Logs">
            <div >
            <Logs/>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path="/PrivateRide">
            <div >
            <PrivateRide/>
            </div>
          </PrivateRoute>
      
       
      </Switch>
    </div>
  );
}


