import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../ContextApis/ProvideAuth';





export const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated }= useAuth();
  console.log("auth",isAuthenticated);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated ? (
          children
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        );
      }}
    />
  );
};
