import React,{useState,useEffect,useRef} from "react";
import  Header  from "./common/Header";
import ReactPaginate from 'react-paginate'; 
import { fetchLogs} from '../api/api';
import { useAuth } from '../ContextApis/ProvideAuth';
import { useUser } from '../ContextApis/ProvideUser';
import { useHistory } from 'react-router-dom';
import Dropdown from "../components/common/Dropdown";
import Button from "../components/common/Button";
import DatePicker from 'react-datepicker';


const Logs = () => {
    const auth =useAuth();
    const ref=useRef();
    var today = new Date();
    
    
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setcurrentPage] = useState(1);
    const [data,setData] =useState([]);
    const history = useHistory();
    const { Detail,stationList,profileData }= useUser();
    const token = Detail?.token;
    const [startDate,setStartDate]=useState(today);
    const [endDate,setEndDate]=useState(today);
    const [startDateValue,setStartDateValue]=useState(today.toISOString());
    const [endDateValue,setEndDateValue]=useState(today.toISOString());
    var profileStation=profileData?.station;
    const [station,setStation]=useState(Detail.role==="master"?profileStation:"");
    const logType=["GATE CONTROL","USER MANAGEMENT","LOGIN"];
    const [logtype,setLogtype]=useState("");
    const [forcePage,setforcePage]=useState();
    const [message,setMessage]=useState();
    useEffect(() => {
      handleFetch();
     },[]);
      useEffect(() => {
       handleFetch();
       
      }, [currentPage]);
     

      const handleFetch = () => {
      
		fetchLogs(token,currentPage,station,startDateValue,endDateValue,logtype)
			.then(res => {
                if (res.status === 200) {
                 
                  if(res.data.data.items===null){
                    setMessage("No Logs To Display.");
                  }else{
                    setMessage("");
                  }
                    
                    setData(res.data.data.items);
                    setPageCount(res.data.data.page_total); 
                    setforcePage(currentPage-1);
                    if(station){
                      let value=stationList.filter((val)=>val.id===station);
                      let { station_name}=value[0];
                      ref.current['station'].value=station_name;
                    }
                  }
                  if (res.status === 401) {
                    auth.signOut(() => history.replace('/'));
                  
                  }
				
                               
			})
			.catch(error => console.error('Error', error));
	};
  const handleView = (selectedObject) => {
   
    setcurrentPage(selectedObject.selected+1);
    handleFetch();
    
  };
  
	const handlePageChange = (selectedObject) => {
   
    
		setcurrentPage(selectedObject.selected+1);
		//handleFetch();
	};

  const onView=()=>{
    //currentPage===1? handleFetch():setcurrentPage(1);
    let val={selected:0};
    handleView(val);
    //setforcePage(0);
   
  //setcurrentPage(1);
    //handleFetch();
  };

  const handleChangeStation=(e)=> {
    let value=stationList.filter((val)=>val.station_name===e.target.value);
    let { id}=value[0]?value[0]:"";
      setStation(id);
    };

    const OnStartDateChange=(e)=>{
      
      setStartDate(e);
      let DateTimeZone=e.toISOString();
      setStartDateValue(DateTimeZone);
    };

    const OnEndDateChange=(e)=>{
      setEndDate(e);
      let DateTimeZone=e.toISOString();
      setEndDateValue(DateTimeZone);
    };

    const ReverseString=(value)=>{
        let val=value.split("T");
        let datatime=val[0].split("-");
        return datatime[1]+"-"+datatime[2]+"-"+datatime[0];
    };

    const timeformat=(datetime)=>{
      let date = new Date(datetime);
      return date.toLocaleString('en-US', { hour: 'numeric',minute:'numeric', hour12: true });
    };

    const handleChangeLogType=(e)=>{
      let value=logType.filter((val)=>val===e.target.value);
      let data=e.target.value==="GATE CONTROL"?"GATE_CONTROLE":e.target.value==="USER MANAGEMENT"?"USER_MANAGEMENT":e.target.value==="LOGIN"?"LOGIN":"";
      //const logType=["GATE_CONTROLE","USER_MANAGEMENT","LOGIN"];
      setLogtype(data);
    };
  
  return (
    <div>
      <Header/>
      
      <form ref={ref}>
      
      <div className="bl-container-grid-10-form" style={{marginBottom:"20px",marginTop:"20px"}}>
      <span className="col-span-3 column-hide" style={{backgroundColor:"#ffffff"}}></span>
      <div className="col-span-2 col-start" style={{width:"250px",display:"flex",justifyContent:"center"}}>
      <div style={{height:"45px",display:"flex",alignItems:"center"}}>Log Type: </div>
      <Dropdown items={logType} handleChangeDropdown={handleChangeLogType} name="logtype" defaultSelectedItem="--All Log Type--" width="140px"/>
      </div>
      <div className="col-span-2 leftpadding-4" style={{width:"250px",display:"flex",justifyContent:"center"}}>
      <div style={{height:"45px",display:"flex",alignItems:"center"}}>Station: </div>
      <Dropdown items={stationList} handleChangeDropdown={handleChangeStation} name="station" defaultSelectedItem="--All Station--" width="150px"/>
      </div> 
      
      <div className=" col-span-2 StartDate leftpadding-6" style={{width:"300px", display:"flex",justifyContent:"left"}}>
      
      <DatePicker 
      className="w-28 pl-1 pt-2 pb-2 mt-1"
      locale="en-US"
      onChange={(e)=>{OnStartDateChange(e)}}
      selected={startDate}
      dateFormat="MM/dd/yyyy"
      />
      <div className=" ml-2 w-6 text-center " style={{display:"flex",alignItems:"center"}}>to</div>
      <div className=" EndDate" style={{width:"150px", display:"flex",justifyContent:"left"}}>
      <DatePicker 
      className="w-28 pl-1 pt-2 pb-2 mt-1"
      locale="en-US"
      onChange={(e)=>{OnEndDateChange(e)}}
      selected={endDate}
      dateFormat="MM/dd/yyyy"
      />
     </div> 
      </div> 
      <div className="text-center  col-end-11 ml-2 " style={{display:"flex",alignItems:"center"}}>
      <Button label="VIEW"  onClick={onView} width="90px"/></div>
      </div>
      </form>
      <div className="input-container" >
      
                    <tr className="bl-container-grid-10" style={{backgroundColor: "#d8d4d4",padding:"20px 0 20px 0"}}>
                        <th className="col-span-2">
                            NAME
                        </th>
                        <th className="col-span-2">
                            LOG TYPE
                        </th>
                        <th className="col-span-2">
                        LOG DATE & TIME
                        </th>
                        <th className="col-span-2">
                            MESSAGE
                        </th>
                       
                        <th className="col-span-2" >
                            STATION
                        </th>
                         
                    </tr>
                    <tr className={message?"bl-container-grid-10 mt-20":"bl-input-hidden"}>
                    <th className=" col-span-10 mt-40 text-gray-600 text-center text-32 font-semibold" >
                      {message}
                    </th></tr>
                    {data?.map ((data,i) => (
                        <div key={i}><div className="bl-container-grid-10"  style={{backgroundColor:"#d8d4d4",padding:"0 0 1px 0"}}>
                        <div className="col-span-10">
                          <tr className="bl-container-grid-10" 
                                style={{backgroundColor:"#ffffff",padding:"20px 0 20px 0px"}}>
                        <td className="col-span-2 text-center">
                            {data.staff_first_name+" "+data.staff_last_name}
                        </td>
                        <td className="col-span-2 text-center">
                            {data.log_type}
                        </td>
                        <td className="col-span-2 text-center">
                            {ReverseString(data.created_at)+"  "+timeformat(data.created_at)}
                        </td>
                        <td className="col-span-2 text-left">
                            {data.log_message}
                        </td>
                        <td className="col-span-2 text-center" >
                            {data.station_name}
                       
                        </td>
                        
                        
                    
    </tr>
    </div>
    </div> </div>
                    ))}
                    
                   
    

                </div>
                {pageCount>1?
                  <ReactPaginate
					pageCount={pageCount}
					pageRange={2}
					marginPagesDisplayed={1}
					onPageChange={handlePageChange}
					containerClassName={'container'}
					previousLinkClassName={'activeLink'}
					breakClassName={'page'}
					nextLinkClassName={'activeLink'}
					pageClassName={'page'}
					disabledClassNae={'disabled'}
					activeClassName={'active'}
          activeLinkClassName={'activeLink'}
          initialPage={0}
          pageLinkClassName={'pageLink'}
          forcePage={forcePage}
          nextClassName={'previousnextPage'}
          previousClassName={'previousnextPage'}
         
				/>:null}
        </div>
    
  );
};
export default Logs;