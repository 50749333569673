import React from "react";
import PropTypes from "prop-types";
const Button = ({
  label,
  type = "button",
  disabled = false,
  onClick,
  width ,
  height,
  fontsize,
  className,
}) => {
  return (
    <button
      disabled={disabled}
      className={"h-12 w-49 bg-bl-yellow text-black text-sm uppercase border-white rounded-sm hover:shadow-default focus:outline-none"}
      style={{width:`${width}`,height:`${height}`,fontSize:`${fontsize}`}}
      onClick={onClick}
      type={type}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string,
  className: PropTypes.string,
};

export default Button;
