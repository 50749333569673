export default function DeleteModal(props) {
  function handleCloseModal() {
    props.setDeleteModal(false);
  }
  return (
    <>
      {props.deleteModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-md">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleCloseModal}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <h1 className="text-xl text-center font-semibold">
                    Confirmation
                  </h1>
                  <p className="my-4 mt-4 text-center font-light text-base leading-relaxed">
                    Are you sure you want to delete the user?
                  </p>
                </div>
                {/*footer*/}
                <div className="flex justify-between p-6 rounded-b">
                  <button
                    className="bg-white-300 border border-solid border-gray-900 w-48 h-12 text-base"
                    type="button"
                    onClick={handleCloseModal}
                  >
                    CANCEL
                  </button>
                  <button
                    className="bg-yellow-300 w-48 h-12 text-base"
                    type="button"
                    onClick={props.handleDelete}
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
